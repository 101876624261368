import React, { useState } from "react";
import { logEvent } from "firebase/analytics";
import { Link } from 'react-router-dom';
import { analytics } from "../../services/firebase";
import { getArticulos } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';

import Autor from "../Autor/Autor";
import Loading from "../Assets/Loading";
import NotA404 from "../Assets/NotA404";
import RegistrarmeBanner from "../RegistrarmeBanner/RegistrarmeBanner";

const Search = () => {
    logEvent(analytics, window.location.pathname);

    const [buscar, setBuscar] = useState("");
    const [sumar, setSumar] = useState(4);

    const { isLoading, articulos, error } = useFirestore(() => getArticulos());

    const baseActivo = articulos.filter(e => e.activo === "1").sort((a, b) => a.date > b.date ? -1 : 1);

    const incrementCount = () => {
        setSumar(sumar + 4);
    };

    const cantidadArticulos = articulos.length;

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <NotA404 />;
    }

    const filteredArticulos = baseActivo.filter(prod =>
        prod.categoria.toLowerCase().includes(buscar.toLowerCase()) ||
        prod.subcategoria.toLowerCase().includes(buscar.toLowerCase()) ||
        prod.autor.toLowerCase().includes(buscar.toLowerCase()) ||
        prod.titular.toLowerCase().includes(buscar.toLowerCase())
    );

    return (
        <section>
            <div className="search">
                <div className="container-search">
                    <input type="text" placeholder="Search..." onChange={e => { setBuscar(e.target.value) }} title="Search" />
                    <article>
                        Search by category, article, a name, just search and find what you are looking for.
                    </article>
                </div>
                <div className="container-base">
                    {filteredArticulos.length === 0 ? (
                        <div className="nothing-found">
                            Nothing here... Search again
                        </div>
                    ) : (
                        filteredArticulos.slice(0, sumar).map(prod => (
                            <Link to={`/${prod.categoria}/${prod.id}`} className="card-search" key={prod.id}>
                                <img src={`../../img/articulos/${prod.id}/img1.webp`} alt={`${prod.titular}`} title={`${prod.titular}`} />
                                <article>
                                    {prod.subcategoria && <h4>{prod.subcategoria.toUpperCase()}</h4>}
                                    {prod.titular && <h3>{prod.titular}</h3>}
                                </article>
                                <Autor {...prod} />
                            </Link>
                        ))
                    )}
                </div>
                {cantidadArticulos >= sumar && (
                    <div className="container-btn">
                        <button onClick={incrementCount} className="btn">LOAD +</button>
                    </div>
                )}
            </div>
            <RegistrarmeBanner />
        </section>
    );
};

export default Search;
