import { useState, useEffect} from 'react';

export const useFirestore = (asyncFn, dependencies = []) => {
    const [articulos, setArticulos] = useState([]);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [ads, setAds] = useState([]);
    const [categorias, setCategorias] = useState([])

    // ADS
    useEffect(() => {
        asyncFn().then(response => {
            setAds(response);
        });
    }, dependencies);

    // ARTICULOS
    useEffect(() => {
        setIsLoading(true);

        asyncFn().then(response => {
            setArticulos(response);
        }).catch(error => {
            setError(error);
        }).finally(() => {
            setIsLoading(false);
        });

    }, dependencies);

    // CATEGORIAS 
    
    useEffect(() => {

        asyncFn().then(response => {
            setCategorias(response)
        })
    }, dependencies)

    return {
        articulos,
        isLoading,
        error,
        ads,
        categorias,
    };
};
