import React from "react";

import { Link } from 'react-router-dom';

const LogoFooter = () => {
    return (
        <Link to="/">
                <img src="../../img/logofooter.png" alt="Happy Tots Territory" className="logo"/>
        </Link>
    )
}

export default LogoFooter