
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { getCategorias } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';

import { LinkHome, LinkInstagram, LinkSearch } from '../Assets/Variables';
import LogoNav from "../Assets/LogoNav";

const NavBar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const { categorias } = useFirestore(() => getCategorias())

    return(
        <nav>

            <div className="navbar">
                <div className="navbar-buttons">
                    <button className="svg" onClick={() => setIsOpen(!isOpen)}>
                    { isOpen
                        ?   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                        :   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>
                    }                
                    </button>
                    <LogoNav />
                    
                    {LinkSearch}
                </div>
                <div className="navbar-home-menu">
                    { categorias.map(cat =>
                    <Link key={cat.id} to={`/${cat.id}`}  className="button">{cat.label}</Link>    
                    )}
                </div>
            </div>

            <div className={ isOpen ? "buttons-open" : "buttons-closed" } onClick={() => setIsOpen(!isOpen)}>
                    {LinkHome}
                    { categorias.map(cat =>
                    <Link key={cat.id} to={`/${cat.id}`}  className="button">{cat.label}</Link>    
                    )}
                <div className="social">
                    {LinkInstagram}                 
                </div>
            </div>
            
        </nav>
    )
}

export default NavBar