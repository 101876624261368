import React from "react";

import { Link } from 'react-router-dom';
import { getCategorias } from "../../services/firebase/firestore";
import { useFirestore } from '../../hooks/useFirestore';
import { LinkHome, LinkInstagram } from '../Assets/Variables';
import LogoFooter from "../Assets/LogoFooter";


const Footer = () => {

    const { categorias } = useFirestore(() => getCategorias())

    return (
        <footer>
            <div className="container">
                <div className="info">
                    <LogoFooter />
                    <div className="social">
                        {LinkInstagram}
                    </div>
                </div>
                <div className="menu">
                    {LinkHome}
                    { categorias.map(cat =>
                    <Link key={cat.id} to={`/${cat.id}`}  className="button">{cat.label}</Link>    
                    )}
                </div>
                
            </div>
            <div className="leyenda">
                2024 nigibit.com | All rights reserved
            </div>
        </footer>
    )
}

export default Footer