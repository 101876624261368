import React, { useState, useRef, useEffect } from "react";
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import emailjs from '@emailjs/browser';
import { logEvent } from "firebase/analytics";
import { analytics } from "../../services/firebase";

import swal from 'sweetalert';

const Registrarme = () => {
    const formRef = useRef();
    const navigate = useNavigate();

    const [contact, setContact] = useState({
        email: "",
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        logEvent(analytics, window.location.pathname);
    }, []);

    const sendEmail = (e) => {
        emailjs.sendForm('gmail', 'newsletter', formRef.current, 'ywpL1dRWg4rq2UiEr')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    const { register, handleSubmit, formState: { errors } } = useForm();

    const createContact = () => {
        addDoc(collection(db, "contact"), { contact })
            .then(() => {
                swal(
                    "¡THANKS FOR SUBSCRIBING!",
                    `keep up with the latests articles!`,
                    "success"
                );
                sendEmail();
                navigate('/');
            })
            .catch(error => {
                console.log(error);
                swal(
                    "¡Oops!",
                    `Something went wrong`,
                    "error"
                );
            });
    };

    return (
        <section>
            <div className="contacto">
                <div className="container-form">
                    <h4>Subscribe to our</h4>
                    <h2>NEWSLETTER</h2>
                    <article>
                        Stay in the loop with our latest updates, and valuable insights delivered right to your inbox. Subscribe to our newsletter today and never miss out on important news and events!
                        <br /><br />
                        Just enter your email address below to get started:
                    </article>
                    <form ref={formRef} onSubmit={handleSubmit(createContact)}>
                        <div>
                            <input className="input"
                                type="email"
                                name="email"
                                placeholder="EMAIL"
                                value={contact.email}
                                {...register('email')}
                                onChange={(e) => setContact({ ...contact, email: e.target.value })}
                            />

                            {contact.email &&
                                <input type="submit" value="SUBSCRIBE" className="btn" title="Subscribe" />
                            }
                        </div>
                    </form>
                    <div className="img">
                        <img src={`../../img/superregistrame.webp`} alt="subscribe" title="subscribe" />
                    </div>
                </div>
                <div className="subs-details">
                    <article>
                        By subscribing, you agree to receive promotional emails from us. You can unsubscribe at any time. Your email address will only be used for the purpose of sending you our newsletter and will not be shared with any third parties.
                    </article>
                </div>
                {/* <div className="container-img">
                    <img src="../img/registrarme.webp" alt="Register" title="Subscribe" />
                </div> */}
            </div>
        </section>
    );
};

export default Registrarme;
