import { db } from ".";
import { getDocs, collection, query, where } from 'firebase/firestore'

export const getArticulos = (categoriaId) => {
    return new Promise((resolve, reject) => {
        const collectionRef = categoriaId 
            ? query(collection(db, 'articulos'), where('categoria', '==', categoriaId)) 
            : collection(db, 'articulos')

            getDocs(collectionRef).then(response => {
                const articulos = response.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                })
                resolve(articulos)
            }).catch(error => {
                reject(error)
            })
    })
}


export const getAds = (adId) => {
    return new Promise((resolve, reject) => {
        const collectionRef = adId 
            ? query(collection(db, 'ads'), where('id', '==', adId)) 
            : collection(db, 'ads')

            getDocs(collectionRef).then(response => {
                const ads = response.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                })
                resolve(ads)
            }).catch(error => {
                reject(error)
            })
    })
}

export const getCategorias = (categoriaId) => {
    return new Promise((resolve, reject) => {
        const collectionRef = categoriaId 
            ? query(collection(db, 'categorias'), where('categoria', '==', categoriaId)) 
            : collection(db, 'categorias')

            getDocs(collectionRef).then(response => {
                const categorias = response.docs.map(doc => {
                    return { id: doc.id, ...doc.data() }
                })
                resolve(categorias)
            }).catch(error => {
                reject(error)
            })
    })
}
