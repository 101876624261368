import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from 'firebase/firestore'
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBo0SXUCJPpKHRn2Z3u70yYpTmxcZiGaPk",
    authDomain: "nigibit-fe9e1.firebaseapp.com",
    projectId: "nigibit-fe9e1",
    storageBucket: "nigibit-fe9e1.appspot.com",
    messagingSenderId: "783750581281",
    appId: "1:783750581281:web:3eaca38f5fcf2d52626760",
    measurementId: "G-RB73CKVHPC"
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics();

export const db = getFirestore(app);

export const date = serverTimestamp();