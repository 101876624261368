import React from "react";
import { useParams, Link } from 'react-router-dom';
import { getArticulos } from "../../../services/firebase/firestore";
import { useFirestore } from '../../../hooks/useFirestore';
import Autor from "../../Autor/Autor";

const Relacionados = () => {
    const { categoryId, articuloId } = useParams();
    const { articulos } = useFirestore(() => getArticulos());

    const refresh = () => window.scrollTo(0, 0);

    const articulosRelacionados = articulos.filter(art => 
        art.categoria === categoryId && art.activo === "1" && art.id !== articuloId
    ).slice(0, 4);

    return (
        <div className="sidebar">
            <h3>RELATED</h3>
            {articulosRelacionados.map(art => (
                <Link to={`/${art.categoria}/${art.id}`} key={art.id} className="relacionados" onClick={refresh}>
                    <div>
                        <img src={`../../img/articulos/${art.id}/img2.webp`} alt={art.titular} title={art.titular}/>
                    </div>
                    <div className="container-relacionados">
                        {art.subcategoria && <span>{art.subcategoria.toUpperCase()}</span>}
                        {art.titular && <h4>{art.titular}</h4>}
                        <Autor {...art}/>
                    </div>
                </Link>
            ))}
        </div>
    );
}

export default Relacionados;
